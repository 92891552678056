<template>
  <div>
    <h1 class="title is-3">
      <span>Ellenőrzések</span>
      <b-field class="is-pulled-right">
        <b-switch v-if="$store.getters.isLawyer"
                  v-model="showOnlyNotFinishedInspections"
                  class="has-text-weight-normal is-size-6">
          Nem Kész ellenőrzések
        </b-switch>
        <b-switch v-model="isDetailedMode"
                  class="has-text-weight-normal is-size-6">
          Részletes nézet
        </b-switch>
        <b-switch v-if="$store.getters.minUserLevelInspector"
                  v-model="isDateBookingMode"
                  class="has-text-weight-normal is-size-6">
          Időpont egyeztetés mód
        </b-switch>
        <b-button @click="refreshInspectionList"
                  icon-left="redo-alt">
          Lista frissítése
        </b-button>
      </b-field>
    </h1>
    <div>
      <div v-if="isDateBookingMode && bookingLimits.length > 0"
           class="mr-1 booking-sidebar-container">
        <b-button @click="refreshInspectionBookingLimits"
                  class="mb-1"
                  size="is-small"
                  icon-left="redo-alt">
          Naptár frissítése
        </b-button>
        <div v-for="(bookingMonthData, index) in bookingLimits"
             :key="`bookingMonthData_month_card_index${index}`"
             class="card mb-1">
          <div class="card-content has-text-centered p-4 has-text-white is-capitalized"
               :class="`has-background-${getInspectionBookingLimitColor(bookingMonthData.db, (Number(bookingMonthData.db) - Number(bookingMonthData.available_inspection_count)))}`">
            {{ getMonthNameByNumber(bookingMonthData.honap) }}
          </div>
          <div class="card-footer">
            <div class="card-footer-item">
              <b-tooltip label="foglalt/maximum"
                         position="is-top">
                {{ bookingMonthData.db - bookingMonthData.available_inspection_count }}/{{ bookingMonthData.db }}
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div :class="{'booking-mode-table-position-fix': isDateBookingMode}">
        <b-table :data="filteredInspectionList"
                 :loading="isLoading"
                 class="mb-6"
                 :default-sort="this.$store.getters.isLawyer ? 'deadline' : 'date'"
                 default-sort-direction="asc"
                 sticky-header
                 :row-class="getInspectionsRowClass"
                 :paginated="(Array.isArray(filteredInspectionList) && filteredInspectionList.length > 20) && isDetailedMode"
                 per-page="20"
                 height="auto"
        >
          <h2 slot="empty">Jelenleg nincsen Önhöz rentelt ellenőrzés</h2>
          <b-table-column field="name"
                          label="Név"
                          width="300"
                          sortable
                          :searchable="isDetailedMode"
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="membership_number"
                          label="Azonosító"
                          width="100"
                          sortable
                          :searchable="isDetailedMode"
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            {{ props.row.membership_number }}
          </b-table-column>
          <b-table-column field="date"
                          label="Dátum"
                          width="150"
                          sortable
                          :searchable="isDetailedMode"
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            {{ formattedDate(props.row.date) }}
          </b-table-column>
          <b-table-column field="address"
                          label="Helyszín"
                          width="200"
                          sortable
                          :searchable="isDetailedMode"
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            {{ props.row.address.inline_address }}
          </b-table-column>
          <b-table-column field="type"
                          label="Típus"
                          width="100"
                          sortable
                          :searchable="isDetailedMode"
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            {{ arrayToString(props.row.type) }}
          </b-table-column>
          <b-table-column field="status_id"
                          label="Státusz"
                          width="100"
                          sortable
                          :searchable="isDetailedMode"
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            <b-tag :type="getStatusClass(props.row.status_id)"
                   class="is-radiusless">
              {{ props.row.status_name }}
            </b-tag>
          </b-table-column>
          <b-table-column field="deadline"
                          v-if="this.$store.getters.isLawyer"
                          label="Határidő"
                          width="100"
                          sortable
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            <b-tag class="is-radiusless">
              {{ formattedDate(props.row.deadline) }}
            </b-tag>
          </b-table-column>
          <b-table-column label="Műveletek"
                          width="120"
                          v-slot="props"
                          header-class="has-background-success has-text-white">
            <b-field>
              <b-button v-if="Number(props.row.status_id) === 1 && $store.getters.minUserLevelInspector"
                        type="is-primary"
                        class="is-radiusless"
                        size="is-small"
                        @click="inspectionBookDate(props.row.id, props.row.reservation_count)">
                Időpont egyeztetés
              </b-button>
              <b-button v-if="Number(props.row.status_id) === 2 && $store.getters.minUserLevelInspector"
                        type="is-success"
                        class="is-radiusless"
                        size="is-small"
                        @click="startInspection(props.row.id)">
                Indítás
              </b-button>
              <b-button v-if="[3, 4].includes(props.row.status_id) && $store.getters.minUserLevelInspector"
                        type="is-primary"
                        class="is-radiusless"
                        size="is-small"
                        @click="openInspection(props.row.id)">
                Folytatás
              </b-button>
              <b-button v-if="Number(props.row.status_id) === 3 && $store.getters.minUserLevelInspector"
                        type="is-danger"
                        class="is-radiusless"
                        size="is-small"
                        @click="deleteInspection(props.row.id)">
                Törlés
              </b-button>
              <b-button v-if="[5, 6].includes(Number(props.row.status_id)) && $store.getters.minUserLevelLawyer"
                        type="is-dark"
                        class="is-radiusless"
                        size="is-small"
                        @click="openInspection(props.row.id)">
                Megtekint
              </b-button>
              <b-button v-if="Number(props.row.status_id) === 5 && $store.getters.isLawyer"
                        type="is-success"
                        class="is-radiusless"
                        size="is-small"
                        @click="reportInspectionFinished(props.row.id)">
                Készre jelentés
              </b-button>
              <b-button v-if="Number(props.row.status_id) === 6 && $store.getters.isLawyer"
                        type="is-danger"
                        class="is-radiusless"
                        size="is-small"
                        @click="revertInspectionFinished(props.row.id)">
                Visszavon kész jelentést
              </b-button>
              <b-button v-if="[2, 3, 4, 5, 6].includes(Number(props.row.status_id)) && $store.getters.minUserLevelInspector"
                        type="is-light"
                        class="is-radiusless"
                        size="is-small"
                        @click="inspectionDetails(props.row.id)">
                Részletek
              </b-button>
            </b-field>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  arrayToString,
  formattedDate,
  getInspectionStatusClass,
  getMonthNameByNumber,
  getInspectionBookingLimitColor,
} from '@/utils/utils'
import { differenceInCalendarDays } from 'date-fns'

export default {
  name: 'Home',
  components: {},
  data: () => {
    return {
      isLoading: false,
      searchText: '',
      isDateBookingMode: false,
      isDetailedMode: false,
      showOnlyNotFinishedInspections: false,
      bookingLimits: [],
      testMe: [
        { name: 'Január', currentReservationCount: 10, maxReservation: 190 },
        { name: 'Február', currentReservationCount: 10, maxReservation: 190 },
        { name: 'Március', currentReservationCount: 10, maxReservation: 190 },
        { name: 'Április', currentReservationCount: 10, maxReservation: 190 },
        { name: 'Május', currentReservationCount: 10, maxReservation: 190 },
        { name: 'Június', currentReservationCount: 10, maxReservation: 190 },
      ],
    }
  },
  methods: {
    arrayToString,
    formattedDate,
    getStatusClass: getInspectionStatusClass,
    getMonthNameByNumber,
    getInspectionBookingLimitColor,
    inspectionDetails (inspectionID) {
      // TODO: cserél felváltva lévő szürke hátterű információblokkra
      this.$store.dispatch('openModal', {
        modalName: 'InspectionCommissionDetailsModal',
        data: {
          inspectionID: inspectionID,
        },
      })
    },
    inspectionBookDate (inspectionID, inspectionBookCount) {
      this.$store.dispatch('openModal',
        {
          modalName: 'InspectionBookDateModal',
          data: {
            inspectionID: inspectionID,
            inspectionBookCount: inspectionBookCount,
          },
        })
    },
    startInspection (inspectionID) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Elindítja az ellenőrzést?',
          confirmFunction: async () => {
            this.isLoading = true
            await this.$store.dispatch('startInspection', inspectionID)
            this.isLoading = false
          },
        },
      })
    },
    deleteInspection (inspectionID) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos törli az ellenőrzés összes adatát?',
          confirmFunction: async () => {
            this.isLoading = true
            await this.$store.dispatch('deleteInspection', inspectionID)
            this.isLoading = false
          },
        },
      })
    },
    openInspection (inspectionID) {
      this.$store.dispatch('openInspection', inspectionID)
    },
    async refreshInspectionList () {
      this.isLoading = true
      await this.$store.dispatch('fetchInspectionList')
      this.isLoading = false
    },
    async refreshInspectionBookingLimits () {
      this.bookingLimits = await this.$store.dispatch('fetchInspectionBookingLimits')
    },
    async reportInspectionFinished (inspectionID) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Elkészült az ellenőrzés?',
          confirmFunction: async () => {
            this.isLoading = true
            await this.$store.dispatch('reportInspectionFinished', inspectionID)
            this.isLoading = false
          },
        },
      })
    },
    async revertInspectionFinished (inspectionID) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Visszavonja a készre jelentést?',
          confirmFunction: async () => {
            this.isLoading = true
            await this.$store.dispatch('revertInspectionFinished', inspectionID)
            this.isLoading = false
          },
        },
      })
    },
    getInspectionsRowClass (row, index) {
      let respClass =
        index % 2 === 0
          ? 'has-background-white' : 'has-background-green-light'
      if (this.$store.getters.isLawyer && Number(row.status_id) === 5 && !this.$store.getters.isAdmin) {
        // eslint-disable-next-line no-unused-vars
        const daysDiff = differenceInCalendarDays(new Date(row.deadline), new Date())
        if (daysDiff <= 5) {
          respClass = 'has-background-danger has-text-white'
        } else if (daysDiff <= 10) {
          respClass = 'has-background-warning'
        } else if (daysDiff <= 15) {
          respClass = 'has-background-info-light'
        }
      }

      return respClass
    },
  },
  computed: {
    filteredInspectionList () {
      if (this.showOnlyNotFinishedInspections) {
        return this.$store.state.inspection.list.filter(inspection => {
          return Number(inspection.status_id) === 5
        })
      } else {
        return this.$store.state.inspection.list
      }
    },
  },
  watch: {
    isDateBookingMode: function (newVal, prevVal) {
      if (newVal) {
        this.refreshInspectionBookingLimits()
      }
    },
  },
  created () {
    // TODO: tmp solutation, later use localStorage config
    if (this.$store.getters.isLawyer && !this.$store.getters.isAdmin) {
      this.isDetailedMode = true
      this.showOnlyNotFinishedInspections = true
    }
    if (this.isDateBookingMode) {
      this.refreshInspectionBookingLimits()
    }
  },
}
</script>

<style scoped>
.booking-sidebar-container {
  position: fixed;
  left: 0.5rem;
  width: 10vw;
  overflow-y: auto;
  max-height: 80vh;
}

.booking-mode-table-position-fix {
  margin-left: 5vw;
}
</style>
